import { useContext } from "react";
import { InventoryContext } from "../contexts/inventory-context";
import { IInventoryContext } from "../types";
import { InventoryCard } from "../components/inventory-card";
import { Draggable } from "react-beautiful-dnd";

export const SelectionView = () => {
  const { selectedInventories, isLimited } = useContext(
    InventoryContext
  ) as IInventoryContext;
  return (
    <section className="flex gap overflow-y-auto">
      {selectedInventories.length ? (
        selectedInventories.map((inventory, index) => (
          <Draggable
            key={`inventory-${inventory.id}-${index}`}
            draggableId={`inventory-${inventory.id}-${index}`}
            index={index}
            isDragDisabled={isLimited}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  ...provided.draggableProps.style,
                }}
                className="bg-white border rounded shadow"
              >
                <InventoryCard
                  key={`inventory-${inventory.id}-${index}`}
                  inventory={inventory}
                />
              </div>
            )}
          </Draggable>
        ))
      ) : (
        <div>Drop here</div>
      )}
    </section>
  );
};
