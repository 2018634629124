import "./App.css";
import { InventoryView } from "./layout/inventory-view";
import { InventoryFilterView } from "./layout/inventory-filter-view";
import { SelectionView } from "./layout/selection-view";
import { TimerBox } from "./components/timer-box";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useContext } from "react";
import { InventoryContext } from "./contexts/inventory-context";
import { IInventoryContext } from "./types";

function App() {
  const {
    setDraggingState,
    setInventories,
    setSelectedInventories,
    selectedInventories,
    inventories,
  } = useContext(InventoryContext) as IInventoryContext;
  const onDragEnd = (result: any) => {
    const { destination, source } = result;

    // If dropped outside the list or no destination
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId) {
      const reorderedSelectedInventories = Array.from(selectedInventories);
      const [removed] = reorderedSelectedInventories.splice(
        result.source.index,
        1
      );
      reorderedSelectedInventories.splice(destination.index, 0, removed);
      setSelectedInventories(reorderedSelectedInventories);
    } else {
      setDraggingState("");
      const reorderedInventories = Array.from(inventories);
      const reorderedSelectedInventories = Array.from(selectedInventories);
      const [removed] = reorderedInventories.splice(result.source.index, 1);
      setInventories(reorderedInventories);

      reorderedSelectedInventories.splice(destination.index, 0, removed);
      setSelectedInventories(reorderedSelectedInventories);
    }
  };

  const onDragStart = (start: any) => {
    setDraggingState(start.draggableId);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragStart}>
      <main className="grid grid-rows-4 h-screen gap">
        <section className="bg-gray-300 px-2 main-grid-layout gap row-span-3 flex flex-1 overflow-hidden">
          <InventoryView />
          <InventoryFilterView />
        </section>
        <section className="bg-gray-300 px-2 main-grid-layout gap">
          <div className="view-grid-layout flex items-center">
            <Droppable droppableId="selectionDroppable" direction="horizontal">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="flex-1 overflow-hidden"
                >
                  <SelectionView />
                </div>
              )}
            </Droppable>
          </div>
          <TimerBox />
        </section>
      </main>
    </DragDropContext>
  );
}

export default App;
