import { ReactNode } from "react";
import ReactDOM from "react-dom";

import "./assets/modal.css";

const Modal = ({ children }: { children: ReactNode }) => {
  return ReactDOM.createPortal(
    <div className="modal-overlay">{children}</div>,
    document.getElementById("root") as HTMLDivElement
  );
};

export default Modal;
