import { BASE_URL } from "../constants";

export const isPrime = (num: number): boolean => {
  if (num < 2) return false;
  for (let i = 2; i <= Math.sqrt(num); i++) {
    if (num % i === 0) return false;
  }
  return true;
};

export const isOdd = (num: number): boolean => {
  return num % 2 === 1;
};

export const generateRandomNumber = (count: number): number[] => {
  const randomNumbers: number[] = [];
  for (let i = 0; i < count; i++) {
    const randNum = Math.floor(Math.random() * 100000);
    randomNumbers.push(randNum);
  }
  return randomNumbers;
};

export const getSmallImageUrl = (id: number): string => {
  return `${BASE_URL}/${id % 1000}/200/200`;
};

export const getLargeImageUrl = (id: number): string => {
  return `${BASE_URL}/${id % 1000}/600/600`;
};
