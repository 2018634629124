import { ChangeEvent, useContext } from "react";
import { Checkbox } from "../components/checkbox";
import { Toggle } from "../components/toggle";
import { Select } from "../components/select";
import { InventoryContext } from "../contexts/inventory-context";
import { IInventoryContext } from "../types";

export const InventoryFilterView = () => {
  const { filters, isLimited, setFilters } = useContext(
    InventoryContext
  ) as IInventoryContext;

  const onChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = target;
    setFilters({
      ...filters,
      [name]:
        type === "checkbox" ? (target as HTMLInputElement).checked : value,
    });
  };

  return (
    <aside className="p-4 bg-white rounded-lg space-y-5">
      <h2 className="font-semibold text-lg">Filters</h2>
      <ul className="space-y-2">
        <li>
          <div className="font-semibold">Is prime:</div>
          <div className="pl-4">
            <Checkbox
              attributes={{
                name: "isPrime",
                checked: filters.isPrime,
              }}
              status={{ disabled: isLimited }}
              onChange={onChange}
            />
          </div>
        </li>
        <li>
          <div className="font-semibold">Is even/odd:</div>
          <div className="pl-4">
            <Toggle
              attributes={{
                name: "isOdd",
                label1: "Even",
                label2: "Odd",
                checked: filters.isOdd,
              }}
              status={{ disabled: isLimited }}
              onChange={onChange}
            />
          </div>
        </li>
        <li>
          <div className="font-semibold">Ends with:</div>
          <div className="pl-4">
            <Select
              attributes={{
                name: "endsWith",
                value: filters.endsWith,
                options: [
                  { label: "-", value: "" },
                  ...Array.from({ length: 10 }).map((num, index) => ({
                    label: `${index}`,
                    value: `${index}`,
                  })),
                ],
              }}
              status={{ disabled: isLimited }}
              onChange={onChange}
            />
          </div>
        </li>
        <li>
          <div className="font-semibold">Is between:</div>
          <div className="pl-4">
            <div>From: {filters.from}</div>
            <input
              type="range"
              min="0"
              max={filters.to}
              name="from"
              value={filters.from}
              disabled={isLimited}
              onChange={onChange}
            />
          </div>
          <div className="pl-4">
            <div>To: {filters.to}</div>
            <input
              type="range"
              min={filters.from}
              max="100000"
              disabled={isLimited}
              name="to"
              value={filters.to}
              onChange={onChange}
            />
          </div>
        </li>
      </ul>
    </aside>
  );
};
