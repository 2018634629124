import { useContext, useMemo } from "react";
import { IInventoryContext } from "../types";
import { InventoryContext } from "../contexts/inventory-context";

export const TimerBox = () => {
  const { seconds } = useContext(InventoryContext) as IInventoryContext;
  const mmss = useMemo(() => {
    const minutes = Math.floor(seconds / 60);
    const second = seconds % 60;
    const formatTime = (time: number) => (time < 10 ? `0${time}` : time);

    return `${formatTime(minutes)}:${formatTime(second)}`;
  }, [seconds]);
  return (
    <div className="flex flex-col items-center justify-center pt-5">
      <div>{seconds === 0 && "Disabled"}</div>
      <div>{mmss}</div>
    </div>
  );
};
