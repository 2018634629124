import { useContext, useEffect, useRef } from "react";
import { InventoryContext } from "../contexts/inventory-context";
import { IInventoryContext } from "../types";
import { InventoryCard } from "../components/inventory-card";
import { Draggable, Droppable } from "react-beautiful-dnd";

export const InventoryView = () => {
  const { inventories, draggingState, hasMore, isLimited, loadMore } =
    useContext(InventoryContext) as IInventoryContext;

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      },
      { rootMargin: "100px", threshold: 0.9 }
    );
    containerRef.current && observer.observe(containerRef.current);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      containerRef.current && observer.disconnect();
    };
  }, [hasMore, loadMore]);

  const isDragged = (draggableId: string, index: number) => {
    return draggableId === draggingState;
  };

  return (
    <div className="view-grid-layout flex-1 overflow-auto scroll-smooth">
      <section className="view-grid-container gap ">
        {inventories.length ? (
          inventories.map((inventory, index) => (
            <Droppable
              droppableId={`inventory-${inventory.id}-${index}`}
              isCombineEnabled
              key={`inventory-${inventory.id}-${index}`}
              isDropDisabled
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="relative"
                >
                  <Draggable
                    key={`inventory-${inventory.id}-${index}`}
                    draggableId={`inventory-${inventory.id}-${index}`}
                    index={index}
                    isDragDisabled={isLimited}
                  >
                    {(provided, snapshot) => (
                      <>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                          }}
                          className="bg-white border rounded shadow "
                        >
                          <InventoryCard
                            key={`inventory-${inventory.id}-${index}`}
                            inventory={inventory}
                          />
                        </div>
                        {snapshot.isDragging &&
                          isDragged(
                            `inventory-${inventory.id}-${index}`,
                            index
                          ) && (
                            <div className="opacity-70 absolute">
                              <InventoryCard
                                key={`inventory-${inventory.id}-${index}`}
                                inventory={inventory}
                              />
                            </div>
                          )}
                      </>
                    )}
                  </Draggable>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))
        ) : (
          <div className="font-semibold">No inventory</div>
        )}
      </section>
      <div ref={containerRef} className="h-5"></div>
    </div>
  );
};
