import { createContext, useEffect, useMemo, useState } from "react";
import { IFilter, IInventory, IInventoryContext } from "../types";
import {
  generateRandomNumber,
  getLargeImageUrl,
  getSmallImageUrl,
  isOdd,
  isPrime,
} from "../helpers";
import { COUNT, MAXIMUM_TIME } from "../constants";

export const InventoryContext = createContext<IInventoryContext | null>(null);

export const InventoryProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [filters, setFilters] = useState<IFilter>({
    isPrime: false,
    isOdd: false,
    endsWith: "",
    from: 0,
    to: 100000,
  });
  const [inventories, setInventories] = useState<IInventory[]>([]);
  const [isLimited, setIsLimited] = useState(false);
  const [pageSize, setPaseSize] = useState(0);
  const [seconds, setSeconds] = useState(MAXIMUM_TIME);
  const [filteredInventories, setFilteredInventories] = useState<IInventory[]>(
    []
  );
  const [loadedInventories, setLoadedInventories] = useState<IInventory[]>([]);
  const [selectedInventories, setSelectedInventories] = useState<IInventory[]>(
    []
  );
  const [draggingState, setDraggingState] = useState<string>("");

  const hasMore = useMemo(() => {
    return filteredInventories.length < inventories.length;
  }, [inventories, filteredInventories]);

  const loadMore = () => {
    setPaseSize(pageSize + 1);
  };

  const loadInventories = () => {
    setLoadedInventories([
      ...(pageSize === 0 ? [] : loadedInventories),
      ...filteredInventories.slice(COUNT * pageSize, COUNT * (pageSize + 1)),
    ]);
  };

  useEffect(() => {
    let tempInventories = [...inventories];
    if (filters.isPrime) {
      tempInventories = tempInventories.filter((inventory) => inventory.prime);
    }
    if (filters.isOdd) {
      tempInventories = tempInventories.filter((inventory) => inventory.odd);
    } else {
      tempInventories = tempInventories.filter((inventory) => !inventory.odd);
    }
    if (filters.endsWith) {
      tempInventories = tempInventories.filter((inventory) =>
        inventory.id.toString().endsWith(filters.endsWith.toString())
      );
    }
    tempInventories = tempInventories.filter(
      (inventory) => inventory.id >= filters.from && inventory.id <= filters.to
    );

    tempInventories = tempInventories.filter(
      (inventory) => inventory.id >= filters.from && inventory.id <= filters.to
    );

    setFilteredInventories(tempInventories);
  }, [filters, inventories]);

  useEffect(() => {
    setPaseSize(0);
  }, [filters]);

  useEffect(() => {
    let tempInventories = [...loadedInventories].filter(
      (inventory) =>
        !selectedInventories.find((selected) => selected.id === inventory.id)
    );

    setLoadedInventories(tempInventories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInventories, setLoadedInventories, loadedInventories.length]);

  useEffect(() => {
    if (selectedInventories.length) {
      setSeconds(MAXIMUM_TIME - 1);
    }
  }, [selectedInventories.length]);

  useEffect(() => {
    if (seconds > 0 && seconds < MAXIMUM_TIME) {
      setIsLimited(false);
      const intervalId = setTimeout(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
      return () => clearTimeout(intervalId);
    }
  }, [seconds, setIsLimited]);

  useEffect(() => {
    if (seconds === 0) {
      setIsLimited(true);
    }
  }, [seconds, setIsLimited]);

  useEffect(() => {
    loadInventories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, filteredInventories]);

  const getInventories = (pageCount?: number) => {
    const inventories = generateRandomNumber(pageCount ?? 100000).map(
      (randNumber) => ({
        id: randNumber,
        prime: isPrime(randNumber),
        odd: isOdd(randNumber),
        imgs: [
          getSmallImageUrl(randNumber),
          getSmallImageUrl(randNumber + 1),
          getSmallImageUrl(randNumber + 2),
        ],
        largeImgs: [
          getLargeImageUrl(randNumber),
          getLargeImageUrl(randNumber + 1),
          getLargeImageUrl(randNumber + 2),
        ],
      })
    );
    setInventories(inventories);
  };

  useEffect(() => {
    getInventories();
  }, []);

  return (
    <InventoryContext.Provider
      value={{
        inventories: loadedInventories,
        draggingState,
        selectedInventories,
        filters,
        hasMore,
        isLimited,
        seconds,
        loadMore,
        setFilters,
        setDraggingState,
        setInventories: setLoadedInventories,
        setSelectedInventories,
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};
