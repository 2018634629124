import { IToggle } from "../types";

export const Toggle: React.FC<IToggle> = ({
  attributes: { label1, label2, name, checked },
  status,
  onChange,
}) => {
  return (
    <>
      <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
        <input
          type="checkbox"
          name={name}
          className="sr-only"
          checked={checked}
          disabled={status?.disabled}
          onChange={onChange}
        />
        <span
          className={`slider mr-3 flex h-[26px] w-[50px] items-center rounded-full p-1 duration-200 ${
            checked ? "bg-primary border shadow" : "bg-[#CCCCCE]"
          }`}
        >
          <span
            className={`dot h-[18px] w-[18px] rounded-full bg-white duration-200 ${
              checked ? "translate-x-6 bg-slate-800" : ""
            }`}
          ></span>
        </span>
        <span className="label flex items-center text-sm font-medium text-black">
          <span className="pl-1"> {checked ? label2 : label1} </span>
        </span>
      </label>
    </>
  );
};
